<template>
	<v-dialog v-model="showing" :persistent="persist" :fullscreen="fullscreen" :max-width="maxWidth" scrollable>
		<v-card :outlined="!fullscreen" :flat="fullscreen" :tile="fullscreen">
			<v-toolbar :color="titleColor" :class="fullscreen ? 'mb-2' : 'mb-2 rounded-t'" dark flat short>
				<v-toolbar-title>{{ title }}</v-toolbar-title>
				<v-spacer></v-spacer>
				<slot name="title-actions">

				</slot>
			</v-toolbar>
			<v-card-text style="height: 100%;">
				<v-container class="px-0 mx-0" fluid>
					<slot name="dialog-body"></slot>
				</v-container>
			</v-card-text>
			<v-card-actions v-if="!hideCardActions">
				<slot name="actions">
					<v-btn @click="$emit('cancel')" text>Cancel</v-btn>
					<v-spacer></v-spacer>
					<v-btn :color="confirmColor" :disabled="confirmDisabled" :loading="confirmLoading"
						   @click="$emit('confirmed')" text v-text="confirmText"></v-btn>
				</slot>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'CardDialog',
	props: {
		showing: {type: Boolean, default: false},
		fullscreen: {type: Boolean, default: false},
		persist: {type: Boolean, default: true},
		maxWidth: {type: [String, Number], default: '300px'},
		title: {type: String, required: true},
		titleColor: {type: String, default: 'primary'},
		confirmColor: {type: String, default: 'primary'},
		confirmText: {type: String, default: 'Confirm'},
		confirmDisabled: {type: Boolean, default: false},
		confirmLoading: {type: Boolean, default: false},
		hideCardActions: {type: Boolean, default: false},

	},
}
</script>

<style scoped>

</style>
