<template>
	<div>
		<v-container fluid>
			<v-row class="mx-md-2">
				<v-spacer></v-spacer>
				<v-col cols="12">
					<v-card v-bind="customCard">
						<v-data-table :headers="headers" :items="pages" :loading="listStatus === status.RUNNING"
									  :items-per-page="10" :page.sync="page" disable-filtering disable-sort
									  hide-default-footer loading-text="Loading... Please wait">

							<template v-slot:top>
								<v-toolbar id="table-toolbar" class="rounded-t" flat>
									<v-toolbar-title>
										Pages
									</v-toolbar-title>

									<v-spacer></v-spacer>

									<v-btn @click="showingCreate = true" class="mr-2" color="primary">
										<v-icon>
											mdi-plus
										</v-icon>
									</v-btn>

									<v-btn @click="list" color="primary">
										<v-icon>
											mdi-refresh
										</v-icon>
									</v-btn>
								</v-toolbar>
							</template>

							<template v-slot:item.actions="props">
								<v-menu right>
									<template v-slot:activator="{ on }">
										<v-btn v-on="on" icon small>
											<v-icon small>mdi-dots-vertical</v-icon>
										</v-btn>
									</template>
									<v-list>
										<v-list-item @click="">Edit</v-list-item>
										<v-list-item @click="showDelete(props.item.id)">Delete</v-list-item>
									</v-list>
								</v-menu>
							</template>

						</v-data-table>

						<v-pagination v-model="page" :length="Math.ceil(pages.length/10)" :total-visible="7"
									  class="my-4"></v-pagination>


					</v-card>
				</v-col>
				<v-spacer></v-spacer>
			</v-row>
		</v-container>

		<card-dialog :showing="showingDelete" @cancel="showingDelete = false" @confirmed="destroy(pageId)"
					 confirm-color="error" confirm-text="Delete" title="Delete Page" title-color="error">
			<template v-slot:dialog-body>
				This action cannot be undone.
			</template>
		</card-dialog>

		<new-page :showing.sync="showingCreate"></new-page>

	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import CardDialog from '@/components/global/CardDialog'
import NewPage from '@/components/featured/NewPage'

export default {
	name: 'PageIndex',
	components: {NewPage, CardDialog},
	data: () => ({
		headers: [
			{text: 'Name', align: 'left', value: 'name', width: '30%'},
			{text: 'Slug', value: 'slug', width: '30%'},
			{text: 'Item Count', value: 'itemCount', width: '20%'},
			{text: 'Maintainer', value: 'lastMaintainedBy', width: '10%'},
			{text: '', value: 'actions', align: 'right', width: '5%'},
		],
		page: 1,
		pageId: null,
		showingCreate: false,
		showingDelete: false,
	}),
	created() {
		this.list()
	},
	methods: {
		...mapActions('featured/page', [
			'list',
			'destroy'
		]),
		showDelete(id) {
			this.pageId = id
			this.showingDelete = true
		}
	},
	computed: {
		...mapGetters('featured/page', [
			'pages',
			'listStatus',
			'createStatus',
			'destroyStatus',
		])
	},
	watch: {
		createStatus(updated) {
			if (updated === this.status.SUCCEEDED) {
				this.list()
			}
		},
		destroyStatus(updated) {
			if (updated === this.status.SUCCEEDED) {
				this.pageId = null
				this.showingDelete = false
				this.list()
			}
		}
	}
}
</script>

<style scoped>

</style>
