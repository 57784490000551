<template>
	<card-dialog :showing="showing" @cancel="$emit('update:showing', false)" @confirmed="create(page)"
				 :confirm-disabled="!valid" confirm-text="Save" max-width="400" title="New Page">
		<template v-slot:dialog-body>
			<v-row dense>
				<v-col cols="12">
					<v-text-field v-model="page.name" :id="newUUID()" dense hide-details label="Page Name"
								  outlined></v-text-field>
				</v-col>
				<v-col cols="12">
					<v-text-field v-model="page.slug" :id="newUUID()" dense hide-details label="Page Slug"
								  outlined></v-text-field>
				</v-col>
			</v-row>
		</template>
	</card-dialog>
</template>

<script>
import CardDialog from '@/components/global/CardDialog'
import {mapActions, mapGetters} from 'vuex'

const defaultPage = {
	name: '',
	slug: '',
}

export default {
	name: 'NewPage',
	components: {CardDialog},
	props: {
		showing: {type: Boolean},
	},
	data: () => ({
		page: {...defaultPage}
	}),
	methods: {
		...mapActions('featured/page', [
			'create'
		])
	},
	computed: {
		...mapGetters('featured/page', [
			'createStatus'
		]),
		valid() {
			return this.page.name.trim() && this.page.slug.trim()
		}
	},
	watch: {
		showing(_) {
			this.page = {...defaultPage}
		},
		createStatus(updated) {
			if (updated === this.status.SUCCEEDED) {
				this.$emit('update:showing', false)
			}
		}
	}
}
</script>

<style scoped>

</style>
